import React, {CSSProperties, RefObject} from 'react';
import _ from 'lodash';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import addToCartButtonStylable from './AddToCartButton.st.css';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import quantityCounterStylable from './QuantityCounter.st.css';
import s from './ProductItem.scss';
import {Counter} from 'wix-ui-tpa/Counter';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {IProduct} from '../../../types/galleryTypes';
import {ProductImage} from './ProductImage/ProductImage';
import {ProductPrice} from './ProductPrice/ProductPrice';
import {QuantityCalculator} from '@wix/wixstores-client-core/dist/es/src/quantity-calculator/quantityCalculator';
import {StatesButton} from 'wix-ui-tpa/StatesButton';
import {withGlobals} from '../../../globalPropsContext';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';

export enum DataHook {
  AddToCartButton = 'product-item-add-to-cart-button',
  LineBetweenNameAndPrice = 'product-item-line-between-name-and-price',
  LinkContainer = 'product-item-container',
  Name = 'product-item-name',
  Price = 'product-item-price',
  ProductDetails = 'product-item-product-details',
  QuantityCounter = 'product-item-quantity-counter',
  QuickViewButton = 'product-item-quick-view-button',
  Ribbon = 'product-item-ribbon',
  Root = 'product-item-root',
  SrOnlyName = 'product-item-sr-only-name',
}

export interface IProductItemProps extends IGalleryGlobalProps {
  disabled?: boolean;
  index: number;
  innerRef?: Function;
  product: IProduct;
  style?: CSSProperties;
  a11yAnnouncer: Announcer;
}

interface IProductItemState {
  errors: {quantity?: string};
  limits: {quantity: {min: number; max: number}};
  quantity: number;
  showHoverPreview: boolean;
}

const PREVIEW_DURATION = 1000;

@autobind
export class ProductItem extends React.Component<IProductItemProps & IProvidedTranslationProps, IProductItemState> {
  private readonly debouncedStopHoverPreview = _.debounce(
    () => this.setState({showHoverPreview: false}),
    PREVIEW_DURATION
  );
  private readonly productLink = React.createRef<HTMLAnchorElement>();
  public addToCartButtonRef: RefObject<StatesButton> = React.createRef();
  public state: IProductItemState = {
    showHoverPreview: false,
    quantity: 1,
    errors: {quantity: undefined},
    limits: {
      quantity: {
        min: 1,
        max: 1,
      },
    },
  };

  public static getDerivedStateFromProps(props: IProductItemProps): Partial<IProductItemState> {
    const inventoryRange = QuantityCalculator.getQuantitiesRange(props.product);
    const inventoryCount = inventoryRange[inventoryRange.length - 1];

    return {
      limits: {
        quantity: {
          min: 1,
          max: inventoryCount,
        },
      },
    };
  }

  public focus(): void {
    this.productLink.current.focus();
  }

  private renderSrOnlyName() {
    return (
      <span data-hook={DataHook.SrOnlyName} className={a11y.srOnly}>
        {this.props.product.name}
      </span>
    );
  }

  private renderName() {
    return (
      <h3 className={s.productName} data-hook={DataHook.Name}>
        {this.props.product.name}
      </h3>
    );
  }

  private renderPrice() {
    const {
      globals: {
        textsMap: {
          productPriceBeforeDiscountSR,
          productOutOfStockText,
          productPriceAfterDiscountSR,
          productPriceWhenThereIsNoDiscountSR,
        },
      },
    } = this.props;
    return (
      <ProductPrice
        product={this.props.product}
        isAddtoCartButtonExists={this.props.globals.styleParams.booleans.gallery_showAddToCartButton}
        textsMap={{
          productPriceBeforeDiscountSR,
          productOutOfStockText,
          productPriceAfterDiscountSR,
          productPriceWhenThereIsNoDiscountSR,
        }}
      />
    );
  }

  private renderLineBetweenNameAndPrice() {
    return (
      <div>
        <hr data-hook={DataHook.LineBetweenNameAndPrice} className={s.productDivider} aria-hidden="true" />
      </div>
    );
  }

  private renderQuickViewButton() {
    return (
      <button
        className={s.quickViewButton}
        data-hook={DataHook.QuickViewButton}
        tabIndex={-1}
        aria-hidden="true"
        onClick={this.handleQuickViewButtonClick}>
        {this.props.globals.textsMap.quickViewButtonText}
      </button>
    );
  }

  private renderRibbon() {
    const {
      product: {ribbon},
    } = this.props;
    return (
      ribbon && (
        <span className={s.ribbon} data-hook={DataHook.Ribbon}>
          {ribbon}
        </span>
      )
    );
  }

  private handleQuickViewButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    const {
      globals: {openQuickView},
      product: {id: productId},
      index,
    } = this.props;

    event.preventDefault();
    event.stopPropagation();
    openQuickView({productId, index});
  }

  private async handleAddToCartButtonClick() {
    const {
      globals: {handleAddToCart},
      product: {id: productId},
      index,
    } = this.props;

    const {quantity} = this.state;

    await handleAddToCart({productId, index, quantity});
  }

  private handleQuantityCounterChange(val: string) {
    const {t} = this.props;
    const {min, max} = this.state.limits.quantity;
    const quantity = parseInt(val, 10) || 0;

    const minimumError = quantity < min && t('gallery.minimumQuantity.error', {minimum: min});
    const maximumError = quantity > max && t('gallery.exceedsQuantity.error', {inventory: max});

    this.setState(
      ({errors}) => {
        const nextErrors = {...errors, quantity: minimumError || maximumError};
        return {quantity, errors: nextErrors};
      },
      () => {
        if (this.state.errors.quantity) {
          this.props.a11yAnnouncer.announce(this.state.errors.quantity);
        } else {
          this.props.a11yAnnouncer.announce(
            this.props.t('gallery.sr.totalQty', {
              quantity,
            })
          );
        }
      }
    );
  }

  private handleProductItemClick(event: React.MouseEvent<HTMLAnchorElement>) {
    const {
      globals: {handleProductItemClick},
      product: {id: productId},
      index,
      disabled,
    } = this.props;

    event.preventDefault();
    if (disabled) {
      return;
    }
    handleProductItemClick({
      biData: {
        productId,
        index,
      },
    });
  }

  private readonly isProductAddedToCartSuccessfully = prevAddedToCartSuccessfully => {
    const {
      globals: {addedToCartSuccessfully: currentAddedToCartSuccessfully, shouldShowAddToCartSuccessAnimation},
      product,
    } = this.props;

    const isProductAddedSuccessfully =
      prevAddedToCartSuccessfully &&
      currentAddedToCartSuccessfully &&
      currentAddedToCartSuccessfully[product.id] !== prevAddedToCartSuccessfully[product.id];

    return isProductAddedSuccessfully && shouldShowAddToCartSuccessAnimation;
  };

  public componentDidUpdate(prevProps: Readonly<IProductItemProps>): void {
    const {
      globals: {
        styleParams: {
          fonts: {
            gallery_hoverType: {value: previousHoverType},
          },
        },
        addedToCartSuccessfully: prevAddedToCartSuccessfully,
      },
    } = prevProps;
    const {
      globals: {
        styleParams: {
          fonts: {
            gallery_hoverType: {value: currentHoverType},
          },
        },
      },
    } = this.props;

    if (previousHoverType !== currentHoverType) {
      this.setState({showHoverPreview: true});

      this.debouncedStopHoverPreview();
    }

    if (this.isProductAddedToCartSuccessfully(prevAddedToCartSuccessfully)) {
      //tslint:disable-next-line no-floating-promises
      this.addToCartButtonRef.current.onProgressReset();
    }
  }

  public shouldRenderQuantityCounter = () => {
    const {
      globals: {
        styleParams: {
          booleans: {gallery_showAddToCartQuantity: isShowQuantityEnabled},
        },
      },
      product,
    } = this.props;

    const isOutOfStock = !product.isInStock;

    return isShowQuantityEnabled && !isOutOfStock;
  };

  public renderQuantityCounter = () => {
    const {
      globals: {
        isMobile,
        styleParams: {
          booleans: {gallery_addToCartButtonShowOnHover: shouldShowAddToCartButtonShowOnHover},
        },
        textsMap,
      },
    } = this.props;

    const {
      quantity,
      limits: {
        quantity: {min, max},
      },
    } = this.state;

    const errors = {
      error: !!this.state.errors.quantity,
      errorMessage: this.state.errors.quantity,
    };

    return (
      <Counter
        {...errors}
        decrementAriaLabel={textsMap['gallery.sr.removeQty']}
        incrementAriaLabel={textsMap['gallery.sr.addQty']}
        aria-label={textsMap['gallery.sr.chooseQty']}
        inputAriaLabel={textsMap['gallery.sr.quantity']}
        onChange={this.handleQuantityCounterChange}
        data-hook={DataHook.QuantityCounter}
        value={quantity}
        min={min}
        max={max}
        className={classNames(quantityCounterStylable.quantityCounter, {
          [s.showOnHover]: shouldShowAddToCartButtonShowOnHover && !isMobile,
        })}
      />
    );
  };

  public renderAddToCartButton = () => {
    const {
      globals: {
        isMobile,
        textsMap,
        styleParams: {
          booleans: {gallery_addToCartButtonShowOnHover: shouldShowAddToCartButtonShowOnHover},
        },
      },
      product,
    } = this.props;

    const isOutOfStock = !product.isInStock;
    const showContactSeller = product.price === 0;
    const shouldDisable = isOutOfStock || showContactSeller;

    let buttonText = textsMap.galleryAddToCartButtonText;
    if (showContactSeller) {
      buttonText = textsMap['gallery.contactSeller.button'];
    } else if (isOutOfStock) {
      buttonText = textsMap['gallery.outOfStock.button'];
    }

    return (
      <>
        {this.shouldRenderQuantityCounter() && this.renderQuantityCounter()}
        <StatesButton
          ref={this.addToCartButtonRef}
          text={buttonText}
          onClick={this.handleAddToCartButtonClick}
          disabled={shouldDisable}
          className={classNames(addToCartButtonStylable.addToCartButton, {
            [s.showOnHover]: shouldShowAddToCartButtonShowOnHover && !isMobile,
          })}
          fullWidth
          dataHook={DataHook.AddToCartButton}
        />
      </>
    );
  };

  // tslint:disable-next-line:cognitive-complexity
  public render() {
    const {
      globals: {
        isMobile,
        isLiveSiteMode,
        productIdToProductPageUrlMap,
        styleParams: {
          booleans: {
            gallery_showPrice: shouldShowPrice,
            gallery_showProductName: shouldShowName,
            gallery_showDividers: shouldShowLineBetweenNameAndPrice,
            showQuickView: shouldShowQuickViewButton,
            gallery_showAddToCartButton: shouldShowAddToCartButton,
          },
          fonts: {
            gallery_hoverType: {value: hoverType},
          },
          numbers: {galleryImageRatio: imageRatioId, gallery_imageMode: imageModeId},
        },
        textsMap: {digitalProductBadgeAriaLabelText},
      },
      product,
      style,
    } = this.props;

    const {showHoverPreview} = this.state;
    const shouldShowProductDetails = shouldShowName || shouldShowLineBetweenNameAndPrice || shouldShowPrice;
    const productPageUrl = productIdToProductPageUrlMap[product.id];

    return (
      <div
        style={style}
        data-hook={DataHook.Root}
        className={classNames(s.productItem, isMobile ? s.none : s[hoverType], {[s.hoverPreview]: showHoverPreview})}>
        <a
          href={isLiveSiteMode ? productPageUrl : null}
          onClick={this.handleProductItemClick}
          className={s.productItemLink}
          data-hook={DataHook.LinkContainer}
          ref={this.productLink}>
          <ProductImage
            classNames={{thumbnail: s.productThumbnail, image: s.productImage}}
            product={product}
            isMobile={isMobile}
            hoverType={hoverType}
            imageRatioId={imageRatioId}
            imageModeId={imageModeId}
            textsMap={{digitalProductBadgeAriaLabel: digitalProductBadgeAriaLabelText}}>
            {this.renderRibbon()}
            {!isMobile && shouldShowQuickViewButton && this.renderQuickViewButton()}
          </ProductImage>
          {!shouldShowName && this.renderSrOnlyName()}
          {shouldShowProductDetails && (
            <div className={s.productDetails} data-hook={DataHook.ProductDetails}>
              {shouldShowName && this.renderName()}
              {shouldShowLineBetweenNameAndPrice && this.renderLineBetweenNameAndPrice()}
              {shouldShowPrice && this.renderPrice()}
            </div>
          )}
        </a>
        {shouldShowAddToCartButton && this.renderAddToCartButton()}
      </div>
    );
  }
}

export const ProductItemWithGlobals = withGlobals(withTranslations()(ProductItem));
